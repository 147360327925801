export enum StandardDatabaseSlugs {
  Asset = 'WorkerbaseStandardAssets',
  Machine = 'WorkerbaseStandardMachines',
  MachineErrors = 'WorkerbaseStandardMachineErrors',
  Workstation = 'WorkerbaseStandardWorkstations',
  WorkOrder = 'WorkerbaseStandardWorkOrders',
  ProductionLine = 'WorkerbaseStandardProductionLines',
  AssetType = 'WorkerbaseStandardAssetTypes',
  MachineType = 'WorkerbaseStandardMachineTypes',
  MaterialType = 'WorkerbaseStandardMaterialTypes',
  ProductType = 'WorkerbaseStandardProductTypes',
}
