import * as Yup from 'yup';
import { StepListItem } from 'components/DocumentBuilder/types';
import { isChecklistStep } from '@workerbase/domain/document/guards';
import { getCustomVariableSchema } from '../components/BuilderStepSettingsCustomVariable';
import { QuestionOption } from './hooks/useDisplayCriteriaOptions';

export const getSectionSettingsValidationSchema = (
  questionOptions: QuestionOption[],
  steps: StepListItem[],
  stepId: string,
) =>
  Yup.object()
    .shape({
      sectionName: Yup.string().required('form-validation.required'),
      hasDisplayCriteria: Yup.boolean(),
      displayCriteria: Yup.object().when(['hasDisplayCriteria'], {
        is: (val: boolean) => val,
        then: (schema) =>
          schema.shape({
            conditions: Yup.array().of(
              Yup.object().shape({
                conditions: Yup.array().of(
                  Yup.object().shape({
                    operator: Yup.string().required('form-validation.required'),
                    name: Yup.string()
                      .required('form-validation.required')
                      .oneOf(
                        questionOptions.map((opt) => opt.value),
                        'form-validation.required',
                      ),
                    value: Yup.string().when('name', {
                      is: (name: string) => !!name,
                      then: (schema) =>
                        // eslint-disable-next-line prefer-arrow-callback -- yup "test" requires function expression callback
                        schema.test('value', 'form-validation.required', function (val, context) {
                          const currentId = context.parent.name;
                          const currentlyPickedQuestion = questionOptions.find((opt) => opt.value === currentId);

                          if (!currentlyPickedQuestion) {
                            return false;
                          }

                          if (isChecklistStep(currentlyPickedQuestion.questionData)) {
                            return currentlyPickedQuestion.questionData.options.some((option) => option._id === val);
                          }

                          return !!val;
                        }),
                      otherwise: (schema) => schema.nullable().optional(),
                    }),
                  }),
                ),
              }),
            ),
          }),
        otherwise: (schema) => schema.nullable(),
      }),
    })
    .concat(getCustomVariableSchema(stepId, steps));
