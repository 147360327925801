import React from 'react';
import { STANDARD_DATABASES, StandardDatabaseSlugs } from '@workerbase/domain/database';
import { WuiMenuItemProps } from '@uiKit/WuiMenuItem/WuiMenu';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { Routes } from '../../../../routes';

import styles from './DatabaseMenu.module.scss';

const STANDARD_DATABASES_SLUGS_ORDERED: StandardDatabaseSlugs[] = [
  StandardDatabaseSlugs.Asset,
  StandardDatabaseSlugs.Machine,
  StandardDatabaseSlugs.MachineErrors,
  StandardDatabaseSlugs.Workstation,
  StandardDatabaseSlugs.WorkOrder,
  StandardDatabaseSlugs.ProductionLine,
];

const STANDARD_DATABASE_TYPES_ORDERED: StandardDatabaseSlugs[] = [
  StandardDatabaseSlugs.AssetType,
  StandardDatabaseSlugs.MachineType,
  StandardDatabaseSlugs.MaterialType,
  StandardDatabaseSlugs.ProductType,
];

export interface DatabaseMenuItem {
  name: string;
  slug: string;
}

const STANDARD_DATABASES_MENU_ITEMS: DatabaseMenuItem[] = STANDARD_DATABASES_SLUGS_ORDERED.map((slug) =>
  STANDARD_DATABASES.find((db) => db.slug === slug),
)
  .filter(Boolean)
  .map((db) => ({ name: db!.name, slug: db!.slug }));

const STANDARD_DATABASE_TYPES_MENU_ITEMS: DatabaseMenuItem[] = STANDARD_DATABASE_TYPES_ORDERED.map((slug) =>
  STANDARD_DATABASES.find((db) => db.slug === slug),
)
  .filter(Boolean)
  .map((db) => ({ name: db!.name, slug: db!.slug }));

const createDatabaseLink = (
  { slug, name }: DatabaseMenuItem,
  index: number,
  arr: DatabaseMenuItem[],
): WuiMenuItemProps => {
  const isLastItem = index === arr.length - 1;
  return {
    label: name,
    key: slug,
    component: NavLink,
    to: `/databases/${slug}`,
    divider: isLastItem,
    draggable: false,
    activeClassName: styles.activeNestedLink,
  } as WuiMenuItemProps;
};

export const DatabaseMenu: WuiMenuItemProps[] = [
  ...STANDARD_DATABASES_MENU_ITEMS.map(createDatabaseLink),
  ...STANDARD_DATABASE_TYPES_MENU_ITEMS.map(createDatabaseLink),
  {
    label: <FormattedMessage id="header.databases.custom" />,
    key: 'custom-database',
    component: NavLink,
    to: Routes.Databases,
    exact: true,
    draggable: false,
  } as WuiMenuItemProps,
];
