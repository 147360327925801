import { ResourceTypes } from '../../common/ResourceTypes.enum';
import { StandardDatabaseSlugs } from './StandardDatabaseSlugs.enum';

export type StandardDatabaseResourceTypes = Extract<
  ResourceTypes,
  | ResourceTypes.Asset
  | ResourceTypes.Machine
  | ResourceTypes.MachineError
  | ResourceTypes.Workstation
  | ResourceTypes.WorkOrder
  | ResourceTypes.ProductionLine
  | ResourceTypes.AssetType
  | ResourceTypes.MachineType
  | ResourceTypes.MaterialType
  | ResourceTypes.ProductType
>;

export const StandardDatabaseResourceTypes = Object.freeze({
  Asset: ResourceTypes.Asset,
  Machine: ResourceTypes.Machine,
  MachineError: ResourceTypes.MachineError,
  Workstation: ResourceTypes.Workstation,
  WorkOrder: ResourceTypes.WorkOrder,
  ProductionLine: ResourceTypes.ProductionLine,
  AssetType: ResourceTypes.AssetType,
  MachineType: ResourceTypes.MachineType,
  MaterialType: ResourceTypes.MaterialType,
  ProductType: ResourceTypes.ProductType,
});

export const StandardDatabaseSlugToResourceType: Readonly<
  Record<StandardDatabaseSlugs, StandardDatabaseResourceTypes>
> = {
  [StandardDatabaseSlugs.Asset]: StandardDatabaseResourceTypes.Asset,
  [StandardDatabaseSlugs.Machine]: StandardDatabaseResourceTypes.Machine,
  [StandardDatabaseSlugs.MachineErrors]: StandardDatabaseResourceTypes.MachineError,
  [StandardDatabaseSlugs.Workstation]: StandardDatabaseResourceTypes.Workstation,
  [StandardDatabaseSlugs.WorkOrder]: StandardDatabaseResourceTypes.WorkOrder,
  [StandardDatabaseSlugs.ProductionLine]: StandardDatabaseResourceTypes.ProductionLine,
  [StandardDatabaseSlugs.AssetType]: StandardDatabaseResourceTypes.AssetType,
  [StandardDatabaseSlugs.MachineType]: StandardDatabaseResourceTypes.MachineType,
  [StandardDatabaseSlugs.MaterialType]: StandardDatabaseResourceTypes.MaterialType,
  [StandardDatabaseSlugs.ProductType]: StandardDatabaseResourceTypes.ProductType,
} as const;

export const StandardDatabaseResourceTypeToSlug: Readonly<
  Record<StandardDatabaseResourceTypes, StandardDatabaseSlugs>
> = {
  [StandardDatabaseResourceTypes.Asset]: StandardDatabaseSlugs.Asset,
  [StandardDatabaseResourceTypes.Machine]: StandardDatabaseSlugs.Machine,
  [StandardDatabaseResourceTypes.MachineError]: StandardDatabaseSlugs.MachineErrors,
  [StandardDatabaseResourceTypes.Workstation]: StandardDatabaseSlugs.Workstation,
  [StandardDatabaseResourceTypes.WorkOrder]: StandardDatabaseSlugs.WorkOrder,
  [StandardDatabaseResourceTypes.ProductionLine]: StandardDatabaseSlugs.ProductionLine,
  [StandardDatabaseResourceTypes.AssetType]: StandardDatabaseSlugs.AssetType,
  [StandardDatabaseResourceTypes.MachineType]: StandardDatabaseSlugs.MachineType,
  [StandardDatabaseResourceTypes.MaterialType]: StandardDatabaseSlugs.MaterialType,
  [StandardDatabaseResourceTypes.ProductType]: StandardDatabaseSlugs.ProductType,
} as const;
