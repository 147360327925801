import { ResourceTypes } from './ResourceTypes.enum';

export const ResourceDictionary: Readonly<{
  [resourceType in ResourceTypes]: {
    singular: string;
    plural: string;
  };
}> = {
  [ResourceTypes.Asset]: { singular: 'Asset', plural: 'Assets' },
  [ResourceTypes.AssetType]: { singular: 'Asset Type', plural: 'Asset Types' },
  [ResourceTypes.Connector]: { singular: 'Connector', plural: 'Connectors' },
  [ResourceTypes.Database]: { singular: 'Database', plural: 'Databases' },
  [ResourceTypes.Device]: { singular: 'Device', plural: 'Devices' },
  [ResourceTypes.Document]: { singular: 'Document', plural: 'Documents' },
  [ResourceTypes.DocumentFormResult]: { singular: 'Form Result', plural: 'Form Results' },
  [ResourceTypes.Function]: { singular: 'Function', plural: 'Functions' },
  [ResourceTypes.Location]: { singular: 'Location', plural: 'Locations' },
  [ResourceTypes.Machine]: { singular: 'Machine', plural: 'Machine' },
  [ResourceTypes.MachineError]: { singular: 'Machine Error', plural: 'Machine Errors' },
  [ResourceTypes.MachineType]: { singular: 'Machine Type', plural: 'Machine Types' },
  [ResourceTypes.MaterialType]: { singular: 'Material Type', plural: 'Material Types' },
  [ResourceTypes.Media]: { singular: 'Media', plural: 'Media' },
  [ResourceTypes.ProductionLine]: { singular: 'Production Line', plural: 'Production Lines' },
  [ResourceTypes.ProductType]: { singular: 'Product Type', plural: 'Product Types' },
  [ResourceTypes.Project]: { singular: 'Project', plural: 'Projects' },
  [ResourceTypes.Role]: { singular: 'Role', plural: 'Roles' },
  [ResourceTypes.Rule]: { singular: 'Rule', plural: 'Rules' },
  [ResourceTypes.Skill]: { singular: 'Skill', plural: 'Skills' },
  [ResourceTypes.Task]: { singular: 'Task', plural: 'Tasks' },
  [ResourceTypes.User]: { singular: 'User', plural: 'Users' },
  [ResourceTypes.Workinstruction]: { singular: 'Workinstruction', plural: 'Workinstructions' },
  [ResourceTypes.WorkOrder]: { singular: 'Work Order', plural: 'Work Orders' },
  [ResourceTypes.Workstation]: { singular: 'Workstation', plural: 'Workstations' },
};
